import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7edzow4axm-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>智慧政务</p>
            </span>
          </span>
        ),
        className: 'title-h1 l7ee08q6bz-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              “智慧政务”利用物联网、云计算、移动互联网、人工智能、数据挖掘、知识管理等技术，提高相关机构办公、监管、服务、决策的智能化水平，形成高效、敏捷、便民的新型政务系统。
            </p>
          </span>
        ),
        className: 'title-content l7ee0lpcysn-editor_css',
      },
      {
        name: 'image~l7ee1qkhld',
        className: 'l7ee242bi9i-editor_css image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu1.jpg',
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ee19csc8g-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>智能办公</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              采用人工智能、知识管理、移动互联网等手段，将传统办公自动化系统改造成为智能办公系统。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ee2czzy06',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu2.jpg',
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ee33mtaz-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>智能监管</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              在智能监管方面，智能化的监管系统可以对监管对象的自动感知、自动识别、自动跟踪。智能化的监管系统可以对突发性事件进行自动报警、自动处置等。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ee3xmkb0s',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu3.png',
      },
      {
        name: 'image~l7ee4odpo',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu4.png',
      },
      {
        name: 'image~l7ee54ylnrk',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu5.jpg',
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ee7ub8w7n-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>智能服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              在智能服务方面，能够自动感知、预测民众所需的服务，为民众提供个性化的服务。导航，业务导览
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ee8h4s69a',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu6.jpg',
      },
      {
        name: 'image~l7ee90fzjnt',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu7.jpg',
      },
      {
        name: 'image~l7ee90zykb',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu8.jpg',
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ee9lsml7-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>智能决策</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              在智能决策方面，采用数据仓库、数据挖掘、知识库系统等技术手段建立智能决策系统，该系统能够根据领导需要自动生成统计报表；开发用于辅助领导决策的“仪表盘”系统，辅助领导做出相关决策。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7eea39s91l',
        className: 'image-zheng-wu',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/zhengwu9.png',
      },
    ],
  },
};

import React, {Component} from 'react';
import {HashRouter as Router, Route} from "react-router-dom";
import {enquireScreen} from 'enquire-js';
import Header2 from './Nav2/Nav0';
import Header from './Nav';
import Home from './Home';
import ThreeD from './ThreeD';
import Construction from './Construction';
import AboutAs from './AboutAs';
import JoinUs from './JoinUs';
import ScrollToTop from './ScrollToTop'
import YuanQu from './YuanQu'
import GongDi from './GongDi'
import JiFang from './JiFang'
import XiaoFang from './XiaoFang'
import ZhengWu from './ZhengWu'
import AnFang from './AnFang'

import {
  Nav00DataSource,
} from './Nav2/data.source.js';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({isMobile: !!b});
    });
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <div>
            <Header2 dataSource={Nav00DataSource} isMobile={this.state.isMobile}/>
            <Route exact path="/" component={Home}/>
            <Route path="/three-d" component={ThreeD}/>
            <Route path="/construction" component={Construction}/>
            <Route path="/about-us" component={AboutAs}/>
            <Route path="/join-us" component={JoinUs}/>
            <Route path="/yuan-qu" component={YuanQu}/>
            <Route path="/gong-di" component={GongDi}/>
            <Route path="/ji-fang" component={JiFang}/>
            <Route path="/xiao-fang" component={XiaoFang}/>
            <Route path="/zheng-wu" component={ZhengWu}/>
            <Route path="/an-fang" component={AnFang}/>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;

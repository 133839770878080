import React from 'react';

export const Feature01DataSource = {
  wrapper: {className: 'home-page-wrapper content0-wrapper'},
  page: {className: 'home-page content0'},
  OverPack: {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                <span>联系我们</span>
                <br/>
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/dh.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>咨询电话</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>0551-64673401</p>
                  <p>0551-64674725</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/yx.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>公司邮箱</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>hfdbkj@163.com</p>
                </span>
              ),
            },
          ],
        },
      },
      // {
      //   name: 'block1',
      //   className: 'content0-block',
      //   md: 4,
      //   xs: 24,
      //   children: {
      //     className: 'content0-block-item',
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'content0-block-icon',
      //         children:
      //           'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/dw.png',
      //       },
      //       {
      //         name: 'title',
      //         className: 'content0-block-title',
      //         children: (
      //           <span>
      //             <p>公司地址</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         children: (
      //           <span>
      //             <span>
      //               <span>
      //                 <p>安徽省合肥市黄山路</p>
      //                 <p>世界之窗创新产业园A1座12层</p>
      //               </span>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/sz.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>工作时间</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>星期一 至 星期六</p>
                      <p>早上 09:00 至 下午18:00</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~ktwoev2ept',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/ewm.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>公众号</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>扫描二维码关注公众号了解更多内容</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: {
    className: 'content10-wrapper ktwkegiyaeb-editor_css home-page-wrapper',
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon ktwkes909wa-editor_css',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {children: 'https://gaode.com/place/B022718HYG', name: '跳转地址'},
      title: {children: '合肥世界之窗创新产业园', name: '弹框标题'},
      content: {
        children: '合肥市蜀山区黄山路451附近 合肥世界之窗创新产业园A1座12层',
        name: '弹框内容',
      },
    },
  },
};
export const Banner51DataSource = {
  wrapper: {className: 'home-page-wrapper banner5 banner5-top dabang-description'},
  page: {className: 'home-page banner5-page'},
  childWrapper: {
    className: 'banner5-title-wrapper description-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>合肥大邦科技有限公司</p>
            </span>
          </span>
        ),
        className: 'banner5-title kus680rwjjp-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content kus67zv4pna-editor_css',
        children: (
          <span>
            <p>
              合肥大邦科技有限公司注册成立于2002年，注册资金5000万，是一家集传统信息产业和现代科学技术相融合的科技型企业。公司下属全资子公司——安徽圆舟率创新科技有限公司致力于研究物联网技术、智能识别技术、三维空间模型展现等技术，力争将传统智能化产业与最新智能技术无缝对接，促进智能化产业突破各种专业壁垒，以更智慧、更开放的方式升级转型。
            </p>
            <p>
              目前公司与众多建筑企业、政府单位及企业园区开展深度合作，在智慧工地、智慧建筑、智慧园区、智慧办公等应用领域，运用计算机视觉、多感知数据采集、多维度数据分析、多面数据展现及多重控制技术，将智能化系统以更灵活多样的方式落地于各终端用户。
            </p>
            <p>
              二十多年来，公司以实际行动践行——“推动前沿智能技术，赋能产业转型升级”这一使命，引进各专业人才，培养技术团队，潜心开发核心竞争力产品。公司先后成功注册多项发明专利，并获得“国家高新企业”、“合肥大数据企业”、“民营科技型企业”等荣誉称号。未来，公司将继续坚持科技创新路线，秉承“工匠精神”，争取在人工智能领域、智能化领域做出更多的衍生产品，从而使项目从售前、到售中及售后，全面实现智慧控制，最终让客户真正切切感受到虚拟数字世界——元宇宙的魅力。
            </p>
            <p>
              “科技创新，智能传感，万物互联”，每一位大邦人愿与之共勉！
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image kuw08lkydib-editor_css da-bang-image',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/10/1111111.jpg',
  },
};
export const Banner50DataSource = {
  wrapper: {className: 'home-page-wrapper banner5 yuan-zhou-lv-description'},
  page: {className: 'home-page banner5-page'},
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>安徽圆舟率创新科技有限公司</p>
              </span>
            </span>
          </span>
        ),
        className: 'banner5-title kus66wnwqlg-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content kus66y4knu-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  安徽圆舟率创新科技有限公司集研发、设计、技术、销售、服务于一体，为中外客户提供工程领域的智能化服务，集大数据与云计算，物联网平台开发、高新技术产品研发，并将之应用到多个领域的专业公司。为1500+工地提供多样化的智慧工地解决方案，工程遍布全国各地。公司核心价值观：质量第一
                  诚信为本 用户至上
                  用心服务，公司旨在让用户拥有智慧生活，提升人们的生活品质，满足用户全方位的要求，为客户持续创造价值。<br/>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image kus2dn4buxr-editor_css',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/10/2222222.jpg',
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper kuxpprnuant-editor_css',
  },
  OverPack: {className: 'home-page content2', playScale: 0.3},
  imgWrapper: {className: 'content2-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20211019153805.png',
    className: 'kuxpquhq62c-editor_css',
  },
  textWrapper: {className: 'content2-text', md: 14, xs: 24},
  title: {
    className: 'content2-title kuxppmk6q7-editor_css',
    children: (
      <span>
        <p>企业文化</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kuxppzui38-editor_css',
    children: (
      <span>
        <p>
          企业文化建设是现代企业发展的重要内容，是精神文明的重要体现，是企业得以长久发展的重要保障。团结一致团队质量第一、顾客至上、诚信为本、精益求精，追求质量卓越，信守合同承诺，严格过程控制，交付满意产品，以先进的技术,卓越的品质，合理的价格及超值的售后服务为您提供可信赖的产品及服务
        使合肥大邦科技有限公司在社会上的知名度有了很大的提升。</p>
      </span>
    ),
  },
};
export const Feature00DataSource = {
  wrapper: {className: 'home-page-wrapper content0-wrapper'},
  page: {className: 'home-page content0'},
  OverPack: {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>公司理念</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>企业愿景</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    打造具有影响力和社会责任感的知名企业。
                    争做中国甚至是国际智慧管控的行业领导者。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>企业使命</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    致力于我们的产品和服务能够使我们的生过变得更美好愉悦，工作变得更加轻松高效。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>企业价值观</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    探索和创新，坚毅执着；正直守信，做对社会负责任的企业；员工和企业共同发展。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {className: 'home-page-wrapper content5-wrapper'},
  page: {className: 'home-page content5'},
  OverPack: {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>公司资质</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%AE%89%E9%98%B2%E4%BA%8C%E7%BA%A7%E8%B5%84%E8%B4%A8%E5%89%AF%E6%9C%AC.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <p>安防二级资质</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~ktv6myu41ve',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%AB%98%E6%96%B0%E6%8A%80%E6%9C%AF%E4%BC%81%E4%B8%9A.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>高新技术企业</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~ktv6o2x6c1t',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%BB%BA%E7%AD%91%E4%BC%81%E4%B8%9A%E8%B5%84%E8%B4%A8.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>建筑企业资格证</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~ktv6orhg25u',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E8%BD%AF%E4%BB%B6%E8%91%97%E4%BD%9C%E6%9D%83.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        计算机软件<span>著作权</span>
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~kus617szqp',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E7%94%B5%E5%AD%90%E4%B8%8E%E6%99%BA%E8%83%BD%E5%8C%96%E8%B5%84%E8%B4%A8%E4%BA%8C%E7%BA%A7%E5%89%AF%E6%9C%AC.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            电子与智能化资质<br/>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~kus618rdk3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%8A%B3%E5%8A%A1%E8%B5%84%E8%B4%A8%E5%89%AF%E6%9C%AC.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          劳务资质<br/>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~kus619adeuf',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%AE%9E%E7%94%A8%E6%96%B0%E5%9E%8B%E4%B8%93%E5%88%A91-02.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          实用新型专利<br/>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~kus619q0l1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {className: 'content5-block-content'},
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%AE%89%E5%85%A8%E7%94%9F%E4%BA%A7%E8%AE%B8%E5%8F%AF%E8%AF%81%E5%89%AF%E6%9C%AC2.jpg',
            className: 'ktv6l4118hn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          安全生产许可证<br/>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: {className: 'home-page-wrapper footer0-wrapper'},
  OverPack: {className: 'home-page footer0', playScale: 0.05},
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 安徽圆舟率创新科技有限公司 &amp;营巢云联 保留一切版权 -
        皖ICP备18024722号<br/>
      </span>
    ),
  },
};

import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>智慧工地大数据平台</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>专业管控智慧工地信息化领域</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              &nbsp;依托物联网、互联网，建立云端大数据管理平台，形成“端+云+大数据”的业务体系和新的模式。大数据平台为管理决策者提供一种全新的直观的扩大视觉和听觉范围的管理工具，实时获得进展汇报，便于追溯与优化，提高工作效率。
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '/#/about-us',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>立即联系</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image kus2akbjwdn-editor_css',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/e41c54f05adacbd98309fb37f36e47b.png',
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <span>我们的优势</span>
                  <br />
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>
              安徽圆舟率创新科技有限公司集研发、设计、技术、销售、服务于一体，为中外客户提供工程领域的智能化服务，圆舟率是国家高新技术企业，住建部劳务管理平台安徽省首家认证对接企业。集大数据与云计算，物联网平台开发、高新技术产品研发，并将之应用到多个领域的专业公司。公司实用新型专利28项，发明专利3项，独立自主开发智慧工地大数据平台–营巢云联已注册品牌商标。为1500+工地提供多样化的智慧工地解决方案，工程遍布全国各地。公司将以最真挚开放的态度欢迎各建筑企业、集成商、产品供应商共同发展合作
              共同互赢互惠。公司核心价值观：质量第一 诚信为本 用户至上
              用心服务。
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 8,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>独立开发 售后无忧</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>24小时快速响应</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>性价比最高</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>一套平台洞悉所有</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>性能稳定</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>已有3000万客户同时使用</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image ktuyorbs1gj-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>高识别率</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>0.3秒动态识别</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>操作简单</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>“零”基础轻松上手</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 8,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>全国平台</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>对接全国平台|住建部认证企业</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%60%24IT%4084%5DJ%28U%29IRR%294%5D%24%29K%7E0.png',
    className: 'ktuvfj4rcmo-editor_css',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>营巢云联</p>
        <p>智慧工地大数据平台</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          营巢云联智慧工地分为PC端版本和手机端版本。管理人员通过PC端，可全方面了解工地各项数据指标。管理人员通过手机端录入工人和实时查看人员的考勤信息，以及方便快捷的查看工人状态。同时现场工作人员也通过手机端下载手机端版本录入本人基本信息，经过授权后随时查看自己的进出场及考勤记录！
        </p>
      </span>
    ),
  },
};
export const Banner51DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper construction-fzlc',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <span>发展历程</span>
              <br />
            </p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'content',
        className: 'banner5-content banner5-content-size',
        children: (
          <span>
            <span>
              <span>
                <p>
                  2015年，公司开始研发智慧工地物联网，同年在中科大物质楼工地项目中成功应用安徽省第1套人脸识别方案。
                </p>
                <p>
                  <br />
                </p>
                <p>
                  2016年，成功注册“营巢云联”商标，同年中标安徽三建集团公司智慧工地物联网所有项目。
                </p>
                <p>
                  <br />
                </p>
                <p>
                  2017年，我们累计获得23项工地相关的软件开发专利，同年中标安徽水利集团智慧工地物联网所有项目。
                </p>
                <p>
                  <br />
                </p>
                <p>2018年，我们荣获国家高新技术企业证书。</p>
                <p>
                  <br />
                </p>
                <p>
                  2019年，我们成为安徽省第1个获得全国建筑工人管理平台对接的民营企业。同年，我们获得合肥市建筑工人管理平台对接授权。
                </p>
                <p>
                  <br />
                </p>
                <p>
                  2021年，截止目前，我们已成功对接省内外600多个智慧工地项目。
                </p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image kus4yppa9n-editor_css',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon-blue.png',
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>应用场景</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvi8nbawc-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/LED%E5%A4%A7%E5%B1%8F%E2%80%94%E2%80%94%E4%BF%A1%E6%81%AF%E6%98%BE%E7%A4%BA%E5%92%8C%E8%A7%86%E9%A2%91%E6%92%AD%E6%94%BE.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>LED大屏</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content ktuviykms38-editor_css',
              children: (
                <span>
                  <p>信息显示和视频播放</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvika6qt-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%8A%A8%E6%80%81%E5%B0%8F%E9%92%A2%E7%82%AE%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E7%B3%BB%E7%BB%9F%E2%80%94%E2%80%94%E5%AF%B9%E6%8E%A5%E5%85%A8%E5%9B%BD%E5%8A%B3%E5%8A%A1%E5%AE%9E%E5%90%8D%E5%88%B6%E5%B9%B3%E5%8F%B0.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>人脸识别考勤设备</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job ktuvmdk7o9a-editor_css',
              children: (
                <span>
                  <p>对接全国劳务实名制平台</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~ktuvmtijaa7',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvika6qt-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%8A%A8%E6%80%81%E6%91%84%E5%83%8F%E5%A4%B4%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB%E7%B3%BB%E7%BB%9F%E2%80%94%E2%80%94%E5%AF%B9%E6%8E%A5%E5%85%A8%E5%9B%BD%E5%8A%B3%E5%8A%A1%E5%AE%9E%E5%90%8D%E5%88%B6%E5%B9%B3%E5%8F%B0.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>摄像头人脸识别</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job ktuvmdk7o9a-editor_css',
              children: (
                <span>
                  <span>
                    <p>对接全国劳务实名制平台</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~ktuvmuhftct',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvika6qt-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E7%9B%91%E6%8E%A7%E4%B8%AD%E5%BF%83%E2%80%94%E2%80%94%E8%BF%9C%E7%A8%8B%E7%9B%91%E6%8E%A7%E5%AE%9E%E6%97%B6%E5%AF%B9%E8%AE%B2.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>监控中心</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job ktuvmdk7o9a-editor_css',
              children: (
                <span>
                  <span>
                    <p>远程监控实时对讲</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~ktuvmvtwbqe',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvika6qt-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E7%9B%91%E6%8E%A7%E2%80%94%E2%80%94%E5%AF%B9%E6%8E%A5%E5%BD%93%E5%9C%B0%E8%B4%A8%E7%9B%91%E7%AB%99%E7%9B%91%E6%8E%A7%E5%B9%B3%E5%8F%B0.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>项目监控</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job ktuvmdk7o9a-editor_css',
              children: (
                <span>
                  <span>
                    <p>对接当地质监站监控平台</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~ktuvmx03uxg',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image ktuvika6qt-editor_css',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E6%89%AC%E5%B0%98%E6%A3%80%E6%B5%8B%E2%80%94%E2%80%94%E7%8E%AF%E4%BF%9D%E5%B1%80%E8%81%94%E7%BD%91%E6%95%B0%E6%8D%AE%E5%AE%9E%E6%97%B6%E4%B8%8A%E4%BC%A0.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>扬尘检测</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job ktuvmdk7o9a-editor_css',
              children: (
                <span>
                  <span>
                    <p>环保局联网数据实时上传</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>合作企业</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E4%B8%80%E5%BB%BA.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7smiicd7',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E4%B8%89%E5%BB%BA.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7snexaj',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E4%B8%AD%E5%9B%BA.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7snvd1dd',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E4%B8%AD%E9%93%81.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7sobtl7i',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%8D%8E%E5%AE%89.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7spvmv4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%AE%89%E5%87%AF.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7sqp8qyq',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%B9%B3%E5%AE%89.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7sr7ljsk',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%BB%BA%E5%B7%A5.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7srmaw1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E6%96%B0%E5%85%B4.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w4o7goj',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E6%B0%B4%E5%88%A9.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w5b5o',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E8%81%94%E5%AE%9D.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w5slk0s',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E8%88%AA%E5%98%89.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w6n52ma',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E8%B7%AF%E6%A1%A5.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w7cp6rn',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E8%B7%AF%E6%B8%AF.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
      {
        name: 'block~ktv7w8j9ke',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%95%BF%E6%BA%90.jpg',
            className: 'ktv7hg2nflm-editor_css',
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>智慧工地相关案例</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>智慧工地以为以下等客户提供技术支持</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E4%B8%80%E5%BB%BA%E5%AE%89%E5%BA%86%E5%BE%BD%E5%88%9B%E6%94%BF%E5%8A%A1%E6%9C%AA%E6%9D%A5%E9%A1%B9%E7%9B%AE/lADPD2eDQl5DfzbNA8DNBQA_1280_960.jpg',
            className: 'ktuz5p9x5w-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>一建安庆徽创政务未来项目</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E4%B8%89%E5%BB%BA%E5%90%88%E8%82%A5%E6%99%BA%E6%85%A7%E4%BA%A7%E4%B8%9A%E5%9B%AD%E9%A1%B9%E7%9B%AE/lADPDg7mRmwUQrXNA8DNBQA_1280_960.jpg',
            className: 'ktuz67t49bp-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>三建合肥智慧产业园项目</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E5%9B%BA%E9%95%87%E6%B1%89%E5%9F%8E%E6%BA%90%E7%AD%91%E9%A1%B9%E7%9B%AE/lADPDhJzxFyCLyvNBQDNA8A_960_1280.jpg',
            className: 'ktuz6bj0ef-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>固镇汉城源筑项目</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E7%91%9E%E9%87%91%E9%87%91%E7%A7%91%E9%AB%98%E9%80%9F%E9%9B%86%E7%BE%8E%E5%A4%A9%E5%AE%B8%E9%A1%B9%E7%9B%AE%E7%89%B9%E8%89%B2%E5%9B%BE.jpg',
            className: 'ktuz82t8jqn-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>江西瑞金金科高速集美天宸项目</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E5%AE%BF%E5%B7%9E%E7%A6%A7%E6%82%A6%E5%8D%8E%E5%BA%9C%E9%A1%B9%E7%9B%AE/lADPDiCpvwaIgpfNBQDNA8A_960_1280.jpg',
            className: 'ktuz8cewrw-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>宿州禧悦华府项目</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E8%8A%9C%E6%B9%96%E4%B8%AD%E4%B8%9E%E6%BB%A8%E6%B1%9F%E6%97%B6%E4%BB%A3%E9%A1%B9%E7%9B%AE/lADPD3zUOlY4x4jNA8DNBQA_1280_960.jpg',
            className: 'ktuz8mu6nv-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>芜湖中丞滨江时代项目</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E8%B7%AF%E6%B8%AF%E5%9C%B0%E9%93%81%E4%B8%83%E5%8F%B7%E7%BA%BF%E5%A4%A9%E9%83%BD%E8%B7%AF%E7%AB%99/lADPDhJzxIegK7nNBQDNA8A_960_1280.jpg',
            className: 'ktuz8vqgbke-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>路港地铁七号线天都路</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E9%A1%B9%E7%9B%AE%E6%96%BD%E5%B7%A5%E6%A1%88%E4%BE%8B/%E8%B7%AF%E6%B8%AF%E6%B4%BE%E6%B2%B3%E5%8F%A3%E9%A1%B9%E7%9B%AE/lADPDiQ3PsmT12jNBQDNA8A_960_1280.jpg',
            className: 'ktuz92lv3ks-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>路港派河口项目</p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 安徽圆舟率创新科技有限公司 &amp;营巢云联 保留一切版权 -
        皖ICP备18024722号<br />
      </span>
    ),
  },
};

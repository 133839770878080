import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 banner5-xiao-fang' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>智慧消防</p>
            </span>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              智慧消防综合管理云平台基于物联网、大数据、云计算等现代信息技术，将分散的火灾自动报警设备、电气火灾监控设备、智慧烟感探测器、智慧消防用水等设备连接形成网络，并对这些设备的状态进行智能化感知、识别、定位，实时动态采集消防信息，通过云平台进行数据分析、挖掘和趋势分析，帮助实现科学预警火灾、网格化管理、落实多元责任监管等目标。实现了无人化值守智慧消防，实现智慧消防“自动化”、“智能化”、“系统化”、用电管理“精细化”的实际需求。从火灾预防，到火情报警，再到控制联动，在统一的系统大平台内运行，用户、安保人员、监管单位都能够通过平台直观地看到每一栋建筑物中各类消防设备和传感器的运行状况，并能够在出现细节隐患、发生火情等紧急和非紧急情况下，在几秒时间内，相关报警和事件信息通过手机短信、语音电话、邮件提醒和APP推送等手段，就迅速能够迅速通知到达相关人员。可大大降低火灾发生概率及损失。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang1.png',
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7edceg7kt-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>三维建模消防设备</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              通过三维建模的方式还原消防区各个消防设备的实际位置，当某个消防设备发生情况时，系统会快速定位相应设备位置，并在模型上高亮显示出来。，消防人员可快速进行应对。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7edd2iy48',
        className: 'image-xiao-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang2.jpg',
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7eddhh4osa-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>自动喷淋</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>遇到火情会自动触发灭火装置</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ede540ejc',
        className: 'image-xiao-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang3.png',
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7edejco5pd-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>消防设备数据采集</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>展示消防设备的实时数据，方便随时查看</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7edf8lqlmd',
        className: 'image-xiao-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang4.png',
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7edfitvoo-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>自动警报</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              发生火情，自动报警，发送消息给消防人员，系统会记录所有设备的报警事件，次数与处理情况
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7edg1j6i2j',
        className: 'image-xiao-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang5.png',
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7edg9km2l-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>统计看板</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              用户可按类型查看所有消防设备的记录，所有数据都以图表的形式展示，用户也可通过输入设备名称等信息，筛选查看设备的各种状态与警报记录。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7edgwafkqe',
        className: 'image-xiao-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/xiaofang6.jpg',
      },
    ],
  },
};

import React from 'react';


// ant-menu-item-selected
// ant-menu-item-active

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo home-header-logo',
    children: 'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/561b9d91514261045efc76e69019c9d.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', a: { children: '首页', href: '/' } },
      { name: 'item1', a: { children: '智慧工地', href: '/construction' } },
      { name: 'item2', a: { children: '智慧物联', href: '/three-d' } },
      { name: 'item3', a: { children: '加入我们', href: '/join-us' } },
      { name: 'item3', a: { children: '关于我们', href: '/about-us' } }
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 banner5-an-fang' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>智慧安防</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              智慧安防是以物联网、云计算、大数据分析等新技术为核心技术，提供一种智慧型、数据化、网络化、协作型一体化的综合安防管理平台。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang1.png',
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ecveb566g-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                <span>&nbsp;视频监控系统</span>
                <br />
              </p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>
                视频监控系统主要在各区域内安装各种类型的监控摄像头，一方面保证人员与公共物资的安全，记录现场突发状况，另一方面便于管理人员实时了解现场情况。
              </p>
              <p>
                {' '}
                管理人员除了在现场通过PC端或硬盘录像机查看监控画面外，还可以远程通过手机APP，随时随地查看调取各个区域监控画面与回看历史监控画面，便于管理人员远程了解区域情况情况并及时做出指挥。
              </p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ecwdld7zb',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang2.png',
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ecww3v31h-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                <span>&nbsp;门禁系统</span>
                <br />
              </p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              在主要出入口安装门禁设备，对出入口通道进行管制，可阻隔无关人员进入
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ecxx8o28d',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang3.jpg',
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ecyf9p5el-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                <span>&nbsp;停车场管理系统</span>
                <br />
              </p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              自动识别车辆，可选择性通过，自动储存每一个停车记录，并且还搭载着图像对比、车主车辆抓拍等功能板块，出入口处会进行信息比对，保护车主车辆安全。
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'image~l7ecz3f9y1',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang4.png',
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7eczlal4y-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>人脸识别系统</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~l7eexr32l3',
        className: '',
        children: (
          <span>
            <span>
              <p>
                人脸识别系统以人脸识别技术为核心，采用区域特征分析算法，融合了计算机图像处理技术与生物统计学原理于一体。
              </p>
              <p>
                人脸识别系统的应用:人脸识别出入管理系统、人脸识别门禁考勤系统、人脸识别监控管理、人脸识别电脑安全防范、人脸识别照片搜索、
              </p>
              <p>
                人脸识别来访登记、人脸识别ATM机智能视频报警系统、人脸识别监狱智能报警系统、人脸识别RFID智能通关系统、人脸识别公安罪犯追逃智能报警系统等等。
              </p>
            </span>
          </span>
        ),
      },
      {
        name: 'image~l7ed0eg96jf',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang5.png',
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ed0w6k8o-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>入侵报警系统</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~l7eeyrgud2t',
        className: '',
        children: (
          <span>
            <p>
              入侵报警系统利用传感器技术和电子信息技术探测并指示非法进入或试图非法进入设防区域或其他危急情况，故意触发紧急报警装置的行为、处理报警信息、发出报警信息的电子系统或网络。
            </p>
            <p>
              入侵报警系统通常由前端设备(包括探测器和紧急报警装置)、传输设备、处理/控制/管理设备和显示/记录设备部分构成。
            </p>
            <p>
              　　前端探测部分由各种探测器组成，是入侵报警系统的触觉部分，相当于人的眼睛、鼻子、耳朵、皮肤等，感知现场的温度、湿度、气味、能量等各种物理量的变化，并将其按照一定的规律转换成适于传输的电信号。
            </p>
            <p>　　操作控制部分主要是报警控制器。</p>
            <p>
              　　监控中心负责接收、处理各子系统发来的报警信息、状态信息等，并将处理后的报警信息、监控指令分别发往报警接收中心和相关子系统。
            </p>
          </span>
        ),
      },
      {
        name: 'image~l7ef18bs9nf',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang7.jpg',
      },
    ],
  },
};
export const Content135DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ed125cpqg-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>无线对讲系统</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~l7ef026rkx',
        className: '',
        children: (
          <span>
            <p>
              无线对讲系统对于安全保卫，设备维护，物业管理等各项管理工作将带来极大的便利。可实现高效、即时的处理各种事件，最大限度地减少可能造成的损失。
            </p>
          </span>
        ),
      },
      {
        name: 'image~l7ef1hthqs',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang8.jpg',
      },
    ],
  },
};
export const Content136DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7ed1591ti-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>巡更</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~l7ef0iq8uol',
        className: '',
        children: (
          <span>
            <p>
              1.园区、小区、学校等物业巡更，支持单机/4G实时上传，APP/小程序/网页多种管理方式，为前端巡更人员提供更方便的巡逻考勤管理。
            </p>
            <p>
              2.工厂巡更，支持前端巡检自定义内容填写、对讲管理、公告下发、隐患视频上报处理等，更好的帮助巡检人员将现场情况回传及存档，后台针对隐患情况统计分析
            </p>
            <p>
              3.针对户外、水库等无法安装实体巡更点的场景，特有GPS定位签到功能，无需额外打卡动作，提高人员工作效率。
            </p>
          </span>
        ),
      },
      {
        name: 'image~l7ef1rm0j5s',
        className: 'image-an-fang',
        children:
          'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/anfang9.jpg',
      },
    ],
  },
};

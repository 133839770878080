import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 banner5-ji-fang' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>&nbsp;智慧机房</p>
            </span>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              智慧机房管理平台系统基于3D仿真可视化技术结合大数据分析，实现机房动环数据监测、设备管理、容量感知、异常报警、IT运维管理等数据动态交互可视，保证机房管理的安全性与有序性，让机房运维管理智慧化、数字化。系统对企业内的机房环境进行实时监测，特别是数字机房的网络、主机以及空间容量信息安全，提供云安全监测服务，防止篡改、钓鱼、木马等风险，并对机房周边警情、机房监控报警信息等环境进行实时监测，实现机房的智能管理。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image l7bn1fg9jir-editor_css',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang1.png',
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>实时数据监测</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper l7bni9c4mg-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block-item-monitor',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang4.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>设备管理</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>各类设备的上下架管理</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block-item-monitor',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang5.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>容量管理</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>功率、机柜、U位等的容量可视化</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block-item-monitor',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang6.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>管线管理</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>线路、管道的绘制与管理</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l7bnkr71xak',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item content0-block-item-monitor',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang7.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>数据监测</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>设备实时运行数据的可视化与异常报警</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>动态安防管理</p>
          </span>
        ),
      },
      {
        name: 'title~l7bno0g89jb',
        className: 'l7bno176oec-editor_css',
        children: (
          <span>
            <p>基于门禁、摄像头、电子围栏等的机房动态安防数据可视化监控</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang8.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>出入门禁</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>各房间门禁数据的出入记录可视</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang9.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>视频监控</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>实时监控画面与异常报警监控联动</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang10.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>电子围栏</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>基于电子围栏的区域与权限监控感知</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l7bnq8d2e3j',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang11.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>图表化显示</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>所有数据图表化显示</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l7bnq9kp2wq',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang12.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>异常报警</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>一旦发生故障危险，实时报警并后台记录报警事件</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l7bnqa3p1ve',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/jifang13.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>配套设备</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>可视化大屏 PC管理端</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l7bo345hzum-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>更多特性</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <p>
                  动力监控部分：市电、配电开关、UPS、蓄电池、精密配电柜、直流/交流配电屏、静态开关柜（STS）和动态开关柜（ATS）、防雷、发电机等参数状态。
                </p>
                <p>
                  环境监控部分：精密空调/普通空调、新/排风机、温湿度、漏水、灯光照明。
                </p>
                <p>安保监控部分：门禁、视频、消防信号、防盗探测。</p>
                <p>IT设施：服务器、交换机、路由器、防火墙等可SNMP管理设备。</p>
                <p>数据中心的供电能力、制冷能力、空间等资源的容量管理。</p>
                <p>数据中心的资产信息</p>
                <p>
                  数据中心的电能利用效率、空调制冷负载系数 、UPS供配电负载系数
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};

import React from 'react';

export const Banner10DataSource = {
  wrapper: {className: 'banner1'},
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: {className: 'banner-user-elem'},
        textWrapper: {
          className: 'banner1-text-wrapper kus1yfpjnf8-editor_css',
        },
        bg: {className: 'bg bg0 kus1tnolb7f-editor_css'},
        title: {
          className: 'banner1-title kus1xt2g1u-editor_css',
          children:
            'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/banner/20220424111803.png',
        },
        content: {
          className: 'banner1-content kus1yj65n3-editor_css',
          children: (
            <span>
              <p>国内专业的智慧工地解决方案</p>
            </span>
          ),
        },
        button: {
          className: 'banner1-button',
          children: (
            <span>
              <span>
                <p>了解更多</p>
              </span>
            </span>
          ),
          href: '/#/construction',
        },
      },
      {
        name: 'elem1',
        BannerElement: {className: 'banner-user-elem'},
        textWrapper: {className: 'banner1-text-wrapper'},
        bg: {className: 'bg bg1 kus3g7w74y-editor_css'},
        title: {
          className: 'banner1-title kus3gx9vxd-editor_css',
          children:
            'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/banner/20220424111331.png',
        },
        content: {
          className: 'banner1-content kus3sr59nr-editor_css',
          children: (
            <span>
              <p>三维可视化物联网解决方案</p>
            </span>
          ),
        },
        button: {
          className: 'banner1-button',
          children: (
            <span>
              <span>
                <p>了解更多</p>
              </span>
            </span>
          ),
          href: '/#/three-d',
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: {className: 'home-page-wrapper content0-wrapper'},
  page: {className: 'home-page content0'},
  OverPack: {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>应用场景</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/yuan-qu';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/17%E4%BC%81%E4%B8%9A%E3%80%81%E5%9B%AD%E5%8C%BA%E3%80%81%E7%89%A9%E4%B8%9A.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>智慧园区</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>充分利用互联网,大数据分析技术将园区内建筑设备可视化管理</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/zheng-wu';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/%E6%94%BF%E5%8A%A1%E5%A4%A7%E5%8E%85.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>智慧政务</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      直观展示政务中心整体动向,利用三维模型导航为市民提供便利服务
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/gong-di';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/%E9%9D%A2%E6%80%A7%E5%B7%A5%E5%9C%B0%E5%B7%A5%E5%85%B7.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>智慧工地</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    基于人脸、图像识别技术为工地提供劳工实名制考勤,生产环境安全检测等服务
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kuxp4gsgph',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/an-fang';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/%E5%AE%89%E9%98%B2%E8%AD%A6%E6%8A%A5.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>智慧安防</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>通过多种预警方式,及时发现安全隐患,为园区安全保驾护航</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kuxp4irtvja',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/ji-fang';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/%E6%9C%BA%E6%88%BF.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>智慧机房</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      实时监控设备负载及机房环境,及时报警处理,减少机房惨案的发生
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kuxp4j6zevo',
        className: 'content0-block',
        md: 8,
        xs: 24,
        onClick: function () {
          window.location.href = '/#/xiao-fang';
        },
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/icon/%E6%B6%88%E9%98%B2%E6%A0%93.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>智慧消防</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          发生事故及时反馈,规划逃生路线与救援路线,为救援行动争取时间
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper kuxpprnuant-editor_css',
  },
  OverPack: {className: 'home-page content2', playScale: 0.3},
  imgWrapper: {className: 'content2-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20211019153805.png',
    className: 'kuxpquhq62c-editor_css',
  },
  textWrapper: {className: 'content2-text', md: 14, xs: 24},
  title: {
    className: 'content2-title kuxppmk6q7-editor_css',
    children: (
      <span>
        <p>企业文化</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kuxppzui38-editor_css',
    children: (
      <span>
        <p>
          企业文化建设是现代企业发展的重要内容，是精神文明的重要体现，是企业得以长久发展的重要保障。团结一致团队质量第一、顾客至上、诚信为本、精益求精，追求质量卓越，信守合同承诺，严格过程控制，交付满意产品，以先进的技术,卓越的品质，合理的价格及超值的售后服务为您提供可信赖的产品及服务
        使合肥大邦科技有限公司在社会上的知名度有了很大的提升。</p>
      </span>
    ),
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper kuxpzht6kuj-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>专业的三维智能物联供应商</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  button: {
    className: '',
    children: {a: {className: 'button', href: '/#/about-us', children: '联系我们'}},
  },
};
export const Footer00DataSource = {
  wrapper: {className: 'home-page-wrapper footer0-wrapper'},
  OverPack: {className: 'home-page footer0', playScale: 0.05},
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 安徽圆舟率创新科技有限公司 &amp;营巢云联 保留一切版权 -
        皖ICP备18024722号<br/>
      </span>
    ),
  },
};

import React from 'react';

export const Banner50DataSource = {
  wrapper: {className: 'home-page-wrapper banner5 kus10khwv5c-editor_css'},
  page: {className: 'home-page banner5-page kus186ems6q-editor_css'},
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>三维智能物联</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <span>
                <p>
                  三维智能物联系统以三维视觉效果、全面数据集成、场景化业务展示为支撑,让管理人员实现对机房环境与设备、安保系统、消防报警、管道线路等可视化管理。
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '/#/about-us',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>立即联系</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image kus0zivk2t9-editor_css',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/7cc1bbf94d7717b17bc03f9033a9483.png',
  },
};
export const Content40DataSource = {
  wrapper: {className: 'home-page-wrapper content4-wrapper'},
  page: {className: 'home-page content4'},
  OverPack: {playScale: 0.3, className: ''},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>三维智能物联运营管理系统</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/%E4%B8%89%E7%BB%B4%E6%99%BA%E6%85%A7%E7%89%A9%E8%81%94.mp4',
      image:
        'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/1F%24A%7BE%7B%29T9%28%291099Y_7J%7E4M.png',
    },
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kus14llfqhc-editor_css',
  },
  OverPack: {className: 'home-page content1', playScale: 0.3},
  imgWrapper: {className: 'content1-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/QHW0PC%7B%7BY9QN%40%5BLLF%60D3N60.png',
    className: 'kus11y5v8dj-editor_css',
  },
  textWrapper: {className: 'content1-text', md: 14, xs: 24},
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>三维立体办事导航</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          政务中心占地面积庞大,可办理的业务也十分繁多,普通的2D平面图无法直观展示园区业务办理流程。
          市民需要办理业务时,在智能查询终端上选择需要办理的业务,系统自动导航到相应的取号机,高亮显示可以办理业务的柜台,同时还可以查看当前排队人数,节省排队时间,为市民提供便利的服务。
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {className: 'home-page-wrapper content2-wrapper'},
  OverPack: {className: 'home-page content2', playScale: 0.3},
  imgWrapper: {className: 'content2-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/X%28%25_WTU26T6S1%24NONQYFZ70.png',
    className: 'kus14nhmchm-editor_css',
  },
  textWrapper: {className: 'content2-text', md: 14, xs: 24},
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>综合安防</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          大邦三维智能物联运营管理系统,集成视屏监控系统、报警系统、门禁管理系统、巡更系统、远程对讲系统、拼接大屏系统、LED发布系统、人脸识别系统、车牌识别系统等系统,统一集中管理,
          对人员区域入侵、柜台人员离岗、黑名单人员闯入、违规进入门禁、不规范停车、强行闯入等安防事件进行预警,通知事件发生点最近的安保人员,及时处理安全隐患。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: {className: 'home-page-wrapper content1-wrapper'},
  OverPack: {className: 'home-page content1', playScale: 0.3},
  imgWrapper: {className: 'content1-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/9QR8%5DBC%25E0N8S1KD%25RQ%5BS_U.png',
    className: 'kus158wrrar-editor_css',
  },
  textWrapper: {className: 'content1-text', md: 14, xs: 24},
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>设备管理</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          园区内包含大量的取号机、查询机、摄像头、门禁、警铃、广播等设备,缺乏统一管控,设备异常、离线无从得知。大邦三维智能物联运营管理系统,集中管控园区内的设备,实时监控设备在线状态,
          设备离线故障及时通知维护人员,即使处理问题。远程控制取号机、查询机省去维护人员频繁往返设备所在地,节约人力成本。摄像头设备支持控制摄像头云台移动,远程调取监控画面,实时保障园区安全。
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: {className: 'home-page-wrapper content2-wrapper'},
  OverPack: {className: 'home-page content2', playScale: 0.3},
  imgWrapper: {className: 'content2-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/2D04%25VT9VTN2LZ61C%248%40KT1.png',
    className: 'kus16tz7yzm-editor_css',
  },
  textWrapper: {className: 'content2-text', md: 14, xs: 24},
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>综合布线</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            园区内设备、网络、电路线分布错综复杂，传统线路图无法直观展示线路分布，故障排查费时费力，整理设备管理无从下手。
            大邦三维智能物联运营管理系统，通过三维可视化的方式，将园区内所有设备、网络、电路、桥架分布立体展示在园区模型中，根据需要分类展示线路走向，
            直观的显示线路及设备之间的联系，使故障排查、设备升级更换、线路设备拓展变得高效、简单。
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: {className: 'home-page-wrapper content1-wrapper'},
  OverPack: {className: 'home-page content1', playScale: 0.3},
  imgWrapper: {className: 'content1-img', md: 10, xs: 24},
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/X%5BH95E9%280HO4HY1%29CP%5D%7EEG9.png',
    className: 'kus17l6pjz-editor_css',
  },
  textWrapper: {className: 'content1-text', md: 14, xs: 24},
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>机房管理</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          随着IT技术的发展和普及，机房数量及配套环境设备日益增多，已成为各大单位的重要组成部分。
          传统机房出现事故无法即使发现和处理，导致影响系统正常运行，损失严重。大邦三维智能物联运营管理系统提供机房温湿度检测、设备运行状态检测、设备离线预案处理、高负载设备提醒等功能，
          提前发现预警机房现在威胁，及时通知机房维护人员，为机房安全保驾护航。
        </p>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: {className: 'home-page-wrapper footer0-wrapper'},
  OverPack: {className: 'home-page footer0', playScale: 0.05},
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2019 安徽圆舟率创新科技有限公司 &amp;营巢云联 保留一切版权 -
        皖ICP备18024722号<br/>
      </span>
    ),
  },
};

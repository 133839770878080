import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/561b9d91514261045efc76e69019c9d.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'home',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/construction',
          children: [
            {
              children: (
                <span>
                  <p>智慧工地</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/three-d',
          children: [
            {
              children: (
                <span>
                  <p>智慧物联</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '/join-us',
          children: [
            {
              children: (
                <span>
                  <p>加入我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~kuw26re8au',
        className: 'header0-item',
        children: {
          href: '/about-us',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>关于我们</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

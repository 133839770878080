import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 banner5-gong-di' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>智慧工地</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              智慧工地系统管理平台利用移动互联、物联网、云计算、大数据等新一代信息技术，彻底改变传统施工现场各参建方的交互方式、工作方式和管理模式，为建设集团、施工企业、监理单位、设计单位、政府监管部门等提供一揽子工地现场管理信息化解决方案。通过人员管理、车辆管理、视频监控、混合料生产监控、设备管理、环境监测、能耗监测七大维度提供面向工程管理人员的现场综合指挥管理平台，实现对劳务人员、大型机械、施工车辆等对象信息、行为、成果的智慧管理。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi1.jpg',
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi3.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>全天候人员与设备管理</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          系统可全天候有效监管施工区域人员与设备情况，辅助管理人员全方面了解工地施工环境，人员情况，施工现场的安全性会得到更好地保障。也能对现场各种智能设备，通过各种信息化的技术进行全面管控，呈现出更全方位的监督管理模式。人员信息与设备信息管理人员都能轻松了解。
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi5.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>安全预警与干预</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          在施工现场及周围，通过应用新型技术手段，全天候、全工况视频监控，标记重点或危险区域，实现安全预警与干预。实现人员违章及时识别与报警、火灾事故快速发现与处置、危险区域越界及时提醒与干预等，防患安全问题。{' '}
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi6.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>提升施工质量，降低风险</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          提升施工质量，降低质量风险和成本风险；全面而精准的过程控制保障，减少现场施工人员，降低人工成本；实时安全预警，从而降低安全风险。
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi7.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>智慧化管理</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          慧工地利用身份证识别和人脸特征采集技术，确保所有人员信息真实唯一。并通过生物识别、活体检测、电子围栏等技术手段，实现对劳务人员进出场、实名考勤、位置、区域安全提醒、值班登记等业务的智慧化管理。
        </p>
      </span>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi8.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>三维模型动态展示</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          根据施工CAD图纸用三维建模的方式，对施工进度进行三维模型还原，还原施工过程，模型可放在系统界面首页360度旋转展示，也可单独独立展示。模型后期也可取代效果图用作最终效果动态展示。
        </p>
      </span>
    ),
  },
};
export const Feature22DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi9.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>数据可视化看板</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          所有数据可转化成更加直观地图表或数据表格形式，大屏显示，方便相关人员查看与领导做出决策。
        </p>
      </span>
    ),
  },
};
export const Feature13DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/gongdi10.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>配套APP</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          智慧工地平台同时配备了PC端版本与手机端APP版本，管理人员可根据个人情况通过配套APP远程查看项目上各项数据情况，劳务人员也可通过APP端查看自己的考勤情况与薪资发放情况，操作简单便捷。
        </p>
      </span>
    ),
  },
};

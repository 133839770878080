import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvagydchlg-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>项目经理</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'title~ktvakj8q76i',
        className: 'ktvaky6z2uj-editor_css',
        children: (
          <span>
            <p>1万-1.5万/月</p>
          </span>
        ),
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <h2>
                                <span>
                                  <b>岗位职责：</b>
                                </span>
                              </h2>
                              <p>
                                1、负责项目现场管理工作，对项目现场人、材、机全权负责，有较强的现场协调指挥能力。
                              </p>
                              <p>
                                2、熟悉国家要求的弱电施工标准，保证现场施工规范，质量符合要求。
                              </p>
                              <p>
                                3、熟悉项目现场变更、申报、回款流程，有一定动手能力，可对图纸或方案做优化设计。
                              </p>
                              <p>4、熟悉招投标流程。</p>
                              <p>5、工作积极主动，有较强的执行力和带头作用。</p>
                              <h2>
                                <b>任职资格：</b>
                              </h2>
                              <p>1、大专以上学历，男性，45岁以下，身体健康。</p>
                              <p>
                                2、熟悉智能化行业，具有2年以上项目管理经验。
                              </p>
                              <p>3、品格端正，性格开朗，敢说敢为。</p>
                              <p>
                                4、具备良好的团队合作意识，有较强的学习能力。
                              </p>
                              <p>有国家颁发的各类职业资格认证者优先。</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content ktvahbz47w-editor_css',
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvamu1rep-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>系统集成工程师</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>6千-8千/月</p>
            </span>
          </span>
        ),
        className: 'title-content ktvand5q1z7-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <h2>
                <b>岗位职责:</b>
              </h2>
              <p>1.态度要积极主动并富有激情，责任心强；忠诚公司、有责任；</p>
              <p>2.稳定、心态平和、勤奋努力；</p>
              <p>3.要适应公司企业文化，遵守制度，遵守保密协议；</p>
              <p>
                4.具备良好的团队合作意识，有很强的学习能力，良好的协调能力；
              </p>
              <p>5.具备网络技术方面基础，熟练使用CAD优先。</p>
              <h2>
                <b>任职资格:</b>
              </h2>
              <p>
                1.大专及以上
                计算机、网络软硬件或电子相关专业，具备2年以上工作经验。
              </p>
              <p>2.爱学习、爱思考、爱钻研；思路清晰、认真细致；勤奋努力；</p>
              <p>3.工作积极主动，善于沟通，动手能力较强。有担当有责任。</p>
              <p>
                4.熟悉智能化、弱电安防工程设计、管理、施工和维护等优先。有项目经验优先。&nbsp;&nbsp;
              </p>
            </span>
          </span>
        ),
        className: 'title-content ktvanzrg4w9-editor_css',
      },
    ],
  },
};
export const Content139DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktwr63dbwa-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>经理助理</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>4千-5千/月</p>
          </span>
        ),
        className: 'title-content ktwr62ihgna-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <h2>
                <b>岗位职责:</b>
              </h2>
              <p>
                1、协助公司上级领导具体工作事务处理及完成，做好领导的助手工作；
              </p>
              <p>
                2、积极完成公司领导分配各类信息的收集、录入、 统计、分析等工作；
              </p>
              <p>3、负责公司一些文档表格处理；</p>
              <p>4、负责公司外部合作单位的外出沟通、外勤及对接工作。</p>
              <h2>
                <b>任职资格:</b>
              </h2>
              <p>
                1、能吃苦耐劳，有团队合作精神，认可公司企业文化，服从公司规章制度；
              </p>
              <p>2、工作细致、认真、有责任心，具备较好的沟通协调能力;</p>
              <p>3、性格不内向，有外联对接工作和组织策划过活动经验优先。</p>
              <h2>
                <b>待遇：</b>
              </h2>
              <p>
                1、每月休息6天，周一--周五9：00-12点；13:30-18：00，周六单双休轮流，不需要加班，不提倡加班，特殊情况调休。
              </p>
              <p>2、工资每年递增上调，公司实行民主决策，工资协商。</p>
              <p>
                3、公司正处于高速发展期需扩大规模，每个考核期都会提拔一批主管和经理，只要你有能力公司给你提供平台。
              </p>
            </span>
          </span>
        ),
        className: 'title-content ktwr6v4d8sq-editor_css',
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvayp9tfbi-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>大客户经理</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>1.5万-2万/月</p>
          </span>
        ),
        className: 'title-content ktvaz7v52a-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <h2>
                  <b>一、岗位职责</b>
                </h2>
                <p>
                  了解智能化（含安防）、物联网行业或建筑行业市场和技术，有政府或国企推广经验者优先。
                </p>
                <p>
                  能够领悟公司市场战略要求，可独立开展市场工作，独立开发客户；擅长沟通表达，目标明确，有较强的商务洽谈能力。
                </p>
                <h2>
                  <b>二、任职要求</b>
                </h2>
                <p>1.大专及以上学历;</p>
                <p>2.积极阳光，充满感染力；</p>
                <p>3.适应公司企业文化，遵守制度，遵守保密协议；</p>
                <p>4.具备良好的团队合作意识，良好的协调能力。</p>
                <h2>
                  <b>三、其他</b>
                </h2>
                <p>
                  1、工作时间，周一--周五9：00-12点；13:30-18：00，周六轮休，不需要加班，特殊情况调休。
                </p>
                <p>2、工资每年递增上调，公司实行民主决策，工资协商。</p>
                <p>
                  3、公司正处于高速发展期需扩大规模，每个考核期都会提拔一批主管和经理，只要你有能力公司给你提供平台。
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content ktvazmwwlzn-editor_css',
      },
    ],
  },
};
export const Content138DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktwqwd3szz-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>项目售前设计</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>1万-1.5万/月</p>
            </span>
          </span>
        ),
        className: 'title-content ktwqwnrngf-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <h2>
                  <b>一、岗位职责</b>
                </h2>
                <p>
                  负责客户沟通与需求分析、项目方案设计和图纸绘制、设计方案宣讲、项目招投标工作和售前项目协调。
                </p>
                <h2>
                  <b>二、岗位要求</b>
                </h2>
                <p>1.大专及以上计算机、网络软硬件或电子相关专业。</p>
                <p>
                  2.熟悉智能化行业主流技术和产品，具备项目设计2年以上工作经验；有独立方案设计能力。
                </p>
                <p>3.适应公司企业文化，遵守制度，遵守保密协议。</p>
                <p>
                  4.具备良好的团队合作意识，有很强的学习能力，良好的协调能力。
                </p>
                <h2>
                  <b>三、其他</b>
                </h2>
                <p>
                  1.工作时间每周五天半，周一--周五9：00-12点；13:30-18：00，不需要加班，特殊情况调休。
                </p>
                <p>2.有建造师证，待遇另谈。</p>
                <p>3.工资每年递增上调，公司实行民主决策，工资协商。</p>
                <p>
                  4.公司正处于高速发展期需扩大规模，每个考核期都会提拔一批主管和经理，只要你有能力公司给你提供平台。
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content ktwqx7gcygn-editor_css',
      },
    ],
  },
};
export const Content135DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvb0fvgh-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>
              <span>销售经理</span>
              <br />
            </p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>1万-1.5万/月</p>
          </span>
        ),
        className: 'title-content ktvb0xeonr-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <h2>
                        <b>岗位职责：</b>
                      </h2>
                      <p>
                        大专及以上，熟悉建委、建筑行业优先，熟悉建材、工地安全设备优先，
                      </p>
                      <p> 市场、销售、计算机、网络软硬件或电子相关专业优先。</p>
                      <p>
                        爱学习、爱思考、爱钻研；思路清晰、认真细致；勤奋努力；工作积极主动，善于沟通。有担当有责任。
                      </p>
                      <p>1、有安防、智能化、物联网相关销售经验优先；</p>
                      <p>2、有市场拓展、展会、网络新媒体营销经验优先；</p>
                      <p>4、有销售经验优先；</p>
                      <p>5、能独立开拓一区域或一行业能力。 </p>
                      <h2>
                        <b>任职资格：</b>
                      </h2>
                      <p>一、要求：</p>
                      <p>
                        1.态度要积极主动并富有激情，责任心强；忠诚公司、有责任；
                      </p>
                      <p>2.稳定、心态平和、勤奋努力；</p>
                      <p>3.要适应公司企业文化，遵守制度，遵守保密协议。</p>
                      <p>
                        4、具备良好的团队合作意识，有很强的学习能力，良好的协调能力；
                      </p>
                      <p>二、待遇：</p>
                      <p>
                        1、每月休息6天，周一--周五9：00-12点；13:30-18：00，周六单双休轮流，不需要加班，
                      </p>
                      <p>&nbsp; &nbsp; &nbsp;不提倡加班，特殊情况调休。</p>
                      <p>2、工资每年递增上调，公司实行民主决策，工资协商。</p>
                      <p>
                        3、公司正处于高速发展期需扩大规模，每个考核期都会提拔一批主管和经理，只要你有能力公司给你提供平台。
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content ktvb1dld22-editor_css',
      },
    ],
  },
};
export const Content136DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvb2s5w2d-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>施工员（弱点智能化）</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>4千-5千/月</p>
          </span>
        ),
        className: 'title-content ktvb37p6h3q-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <h2>
                        <b>岗位职责:</b>
                      </h2>
                      <p>
                        1、安防弱电系统布线和设备安装调试工作及售后维修服务工作；
                      </p>
                      <p>
                        2、负责项目开槽、线管敷设、线路敷设、桥架安装、设备安装等工作；
                      </p>
                      <p>3、负责日常维修，计划检修、维护工作；</p>
                      <p>
                        4、负责公司智慧工地项目及智能化项目所有设备安装调试工作；
                      </p>
                      <p>5、能短期出差。</p>
                      <h2>
                        <b>任职资格:</b>
                      </h2>
                      <p>1、计算机相关专业，中专及以上学历；</p>
                      <p>
                        2、工作热情大方、认真，责任心强，要有吃苦耐劳精神；诚实信用，工作积极主动，&nbsp;<span
                        >
                          沟通及应变能力强，
                        </span>
                      </p>
                      <p>
                        <span>
                          &nbsp; &nbsp; &nbsp;
                          要有团队合作精神和优秀的服务意识；
                        </span>
                      </p>
                      <p>
                        3、有良好的语言沟通能力，善于和客户沟通；动手能力强，积极上进，善于学习并掌握新技术；
                      </p>
                      <p>
                        4、熟悉整个智能化弱电系统原理、施工、调试；能面对解决客户提出的问题；
                      </p>
                      <p>
                        5、从事弱电施工行业1年以上经验，具有电工、网络工程、弱电安装经验、会CAD制图优先。&nbsp;&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content ktvb3fo9axm-editor_css',
      },
    ],
  },
};
export const Content137DataSource = {
  OverPack: {
    className: 'home-page-wrapper ktvb47ht2o7-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>销售助理</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>5千-7千/月</p>
          </span>
        ),
        className: 'title-content ktvb4oaim9g-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <h2>
                <b>岗位职责：</b>
              </h2>
              <p>
                1、负责项目现场管理工作，对项目现场人、材、机全权负责，有较强的现场协调指挥能力。
              </p>
              <p>
                2、熟悉国家要求的弱电施工标准，保证现场施工规范，质量符合要求。
              </p>
              <p>
                3、熟悉项目现场变更、申报、回款流程，有一定动手能力，可对图纸或方案做优化设计。
              </p>
              <p>4、熟悉招投标流程。</p>
              <p>5、工作积极主动，有较强的执行力和带头作用。</p>
              <h2>
                <b>任职资格：</b>
              </h2>
              <p>1、大专以上学历，男性，45岁以下，身体健康。</p>
              <p>2、熟悉智能化行业，具有2年以上项目管理经验。</p>
              <p>3、品格端正，性格开朗，敢说敢为。</p>
              <p>4、具备良好的团队合作意识，有较强的学习能力。</p>
            </span>
          </span>
        ),
        className: 'title-content ktvb53i96uh-editor_css',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper join-us-content11',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>如果有你心仪的职位，立即联系我们吧</p>
          </span>
        ),
        className: 'title-content ktwlik1bgxe-editor_css',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '/#/about-us',
        children: (
          <span>
            <p>立即联系</p>
          </span>
        ),
      },
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          Copyright © 2019 安徽圆舟率创新科技有限公司 &amp;营巢云联 保留一切版权
          - 皖ICP备18024722号<br />
        </span>
      </span>
    ),
  },
};

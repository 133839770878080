import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 banner5-yuan-qu' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>智慧园区</p>
          </span>
        ),
        className: 'banner5-title',
      },

      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              智慧园区可视化管理系统基于数字孪生的三维技术为基础，将物联网，大数据分析，智能识别等新一代信息技术进行整合，通过可视化的管理方式，大屏显示，实时，动态，直观的对园区内的建筑设备从宏观到微观进行全方位的管理。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/yuanqu1.png',
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/yuanqu3.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '场景化运营管理' },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          园区实现场景化运营管理，完整呈现园区内的楼宇以及周边环境的建筑、道路、桥梁分布，从园区外部到房间的内部结构实现多层级管理。与各平台进行信息整合，实现园区日常整体态势及突发事件联动，辅助管理人员提升管控力度和处置突发事件效率。
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/yuanqu5.jpg',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: '可视化设备、线路管理' },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          基于场景对园区楼宇内供配电、给排水、照明系统详细运维管理，实时掌握园区的能耗、设备运营数据，设备空间分布及运行工况。设备出现告警时快速定位到告警设备具体空间位置，并查看详细信息，并支持远程控制设备开关。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/yuanqu6.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '视频监控、巡查、停车管理等系统' },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          集成视频监控、巡查、停车管理等系统，场景内实时看到人员车辆的具体位置及状态，并可对同一区域空间内设备进行关联管理操作，如：对于触发告警的事件，可联动最近监控摄像头画面，通知附近最近巡视人员前往查看告警事件，根据现场反控及时操作周边门禁等设施的开关状态。
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://ahyzl-home-site.oss-cn-beijing.aliyuncs.com/08/22/assets/yuanqu7.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: '配套APP移动端' },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>系统配套APP移动端，方便管理人员管理</p>
      </span>
    ),
  },
};
